import { TransitionsOptions } from '@material-ui/core/styles';

export const initializeTransitions = (config?: TransitionsOptions): TransitionsOptions => {
  return {
    duration: {
      standard: 0.4,
    },
    ...config,
  };
};
