import { Overrides } from '@material-ui/core/styles/overrides';

import { colors } from '@shared/theme/colours';

export const initializeListItemOverrides = (config?: Overrides['MuiListItem']): Overrides['MuiListItem'] => {
  return {
    root: {
      '&.Mui-selected': {
        backgroundColor: colors.blue.lightest,

        '&:hover': {
          backgroundColor: colors.blue.lightest,
        },
      },
    },
    button: {
      '&:hover': {
        backgroundColor: colors.blue.lightest,
      },
    },
    ...config,
  };
};
