import { ShapeOptions } from '@material-ui/core/styles/shape';

export const defaultShape: ShapeOptions = {
  borderRadius: 4,
};

export const customBorderRadius = {
  primary: 30,
};

export const initializeShape = (config?: ShapeOptions): ShapeOptions => {
  return {
    borderRadius: defaultShape.borderRadius,
    ...config,
  };
};
