import { alpha } from '@material-ui/core/styles';
import { Overrides } from '@material-ui/core/styles/overrides';

import { colors } from '@shared/theme/colours';

export const initializeRadioOverrides = (config?: Overrides['MuiRadio']): Overrides['MuiRadio'] => {
  return {
    root: {},
    colorSecondary: {
      color: colors.black.darkest,

      '&.Mui-checked': {
        color: colors.black.darkest,
      },

      '&.Mui-disabled': {
        color: alpha(colors.black.darkest, 0.4),
      },
    },
    ...config,
  };
};
