import { Overrides } from '@material-ui/core/styles/overrides';

export const initializeFormControlLabelOverrides = (
  config?: Overrides['MuiFormControlLabel']
): Overrides['MuiFormControlLabel'] => {
  return {
    root: {
      marginLeft: 0,
    },
    ...config,
  };
};
