// Buttons
export enum ButtonColour {
  primarySpecial = 'primarySpecial',
  primary = 'primary',
  primaryGhost = 'primary-ghost',
  secondary = 'secondary',
  secondaryColour = 'secondary-colour',
  secondaryGhost = 'secondary-ghost',
  error = 'error',
  teal = 'teal',
}

export enum ButtonSize {
  extraSmall = 'extraSmall',
  small = 'small',
  mediumLegacy = 'mediumLegacy', // NOTE: Legacy to support 36px height, i.e. our existing inputs
  medium = 'medium',
  large = 'large',
  // Icon sizes
  iconSmall = 'iconSmall',
  iconLarge = 'iconLarge',
  iconOnly = 'iconOnly',
}

// Selects
export enum SelectColour {
  primarySpecial = 'primarySpecial',
  primary = 'primary',
  secondary = 'secondary',
  secondaryColour = 'secondary-colour',
  secondaryGhost = 'secondary-ghost',
  error = 'error',
  teal = 'teal',
}

export enum SelectMenuPosition {
  BottomRight = 'bottomRight',
  BottomLeft = 'bottomLeft',
  TopRight = 'topRight',
  TopLeft = 'topLeft',
}
