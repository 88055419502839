import { IUser } from '@shared/models/IUser';

// --- Event Keys ---

export enum PrivateEnvUserEventKey {
  SocietyMatchMessageAdded = 'SocietyMatchMessageAdded',
}

export enum PrivateSocietyEventKey {
  AcquisitionPublishedToSociety = 'AcquisitionPublishedToSociety',
  LettingPublishedToSociety = 'LettingPublishedToSociety',
}

export enum PrivateUserEventKey {
  BulkActionCompleted = 'BulkActionCompleted',
  BulkActionProgress = 'BulkActionProgress',
}

export type PusherEventKey = PrivateEnvUserEventKey | PrivateSocietyEventKey | PrivateUserEventKey;

// --- Events ---

export interface BulkActionCompletedPayload {
  failed_text: string | null;
  job_id: number;
  subtext: string;
  success_text: string;
  title: string;
  type: PrivateUserEventKey.BulkActionCompleted;
  url_text: string | null;
  url: string | null;
}

export interface BulkActionProgressPayload {
  job_id: number;
  progress: number;
  subtext: string;
  title: string;
  type: PrivateUserEventKey.BulkActionProgress;
}

export interface LegacyEventPayload {
  id: number;
  user: IUser; // TODO: Check ok
  headline: string;
  subline: string;
  message: string;
}

export interface SocietyMatchMessageAddedPayload {
  disposal_id: number;
  disposal_organisation_id: number;
  message: {
    body: string; // TODO: Check ok
    id: number;
    object_id: number;
    user_id: number;
    user: IUser; // TODO: Check ok
  };
  requirement_id: number;
  requirement_organisation_id: number;
}

export type PusherEventPayload = {
  [PrivateSocietyEventKey.AcquisitionPublishedToSociety]: LegacyEventPayload;
  [PrivateUserEventKey.BulkActionCompleted]: BulkActionCompletedPayload;
  [PrivateUserEventKey.BulkActionProgress]: BulkActionProgressPayload;
  [PrivateSocietyEventKey.LettingPublishedToSociety]: LegacyEventPayload;
  [PrivateEnvUserEventKey.SocietyMatchMessageAdded]: SocietyMatchMessageAddedPayload;
};

// --- Notifications ---

export enum PusherNotificationKey {
  UserOrTeamMentionedOnInterestScheduleComment = 'userOrTeamMentionedOnInterestScheduleComment',
  UserOrTeamMentionedOnRequirementComment = 'userOrTeamMentionedOnRequirementComment',
  UserOrTeamMentionedOnShortlistComment = 'userOrTeamMentionedOnShortlistComment',
  UserOrTeamMentionedOnSocietyAcquisitionComment = 'userOrTeamMentionedOnSocietyAcquisitionComment',
}

export interface UserOrTeamMentionedOnInterestScheduleCommentPayload {
  disposal_id: number;
  headline: string;
  id: number;
  match_id: number;
  message: string;
  subline: string;
  type: PusherNotificationKey.UserOrTeamMentionedOnInterestScheduleComment;
  user: IUser; // TODO: Check ok
}

export interface UserOrTeamMentionedOnRequirementCommentPayload {
  acquisition_id: number;
  headline: string;
  id: number;
  message: string;
  subline: string;
  type: PusherNotificationKey.UserOrTeamMentionedOnRequirementComment;
  user: IUser; // TODO: Check ok
}

export interface UserOrTeamMentionedOnShortlistCommentPayload {
  headline: string;
  id: number;
  match_id: number;
  message: string;
  requirement_id: number;
  subline: string;
  type: PusherNotificationKey.UserOrTeamMentionedOnShortlistComment;
  user: IUser; // TODO: Check ok
}

export interface UserOrTeamMentionedOnSocietyAcquisitionCommentPayload {
  headline: string;
  id: number;
  message: string;
  society_acquisition_id: number;
  subline: string;
  type: PusherNotificationKey.UserOrTeamMentionedOnSocietyAcquisitionComment;
  user: IUser; // TODO: Check ok
}

export type PusherNotificationPayload = {
  [PusherNotificationKey.UserOrTeamMentionedOnInterestScheduleComment]: UserOrTeamMentionedOnInterestScheduleCommentPayload;
  [PusherNotificationKey.UserOrTeamMentionedOnRequirementComment]: UserOrTeamMentionedOnRequirementCommentPayload;
  [PusherNotificationKey.UserOrTeamMentionedOnShortlistComment]: UserOrTeamMentionedOnShortlistCommentPayload;
  [PusherNotificationKey.UserOrTeamMentionedOnSocietyAcquisitionComment]: UserOrTeamMentionedOnSocietyAcquisitionCommentPayload;
};
