import { ThemeOptions } from '@material-ui/core/styles/createTheme';
import { TypographyOptions } from '@material-ui/core/styles/createTypography';

export const fontFamily = 'Inter, sans-serif';
// export const fontFamily = 'sofia-pro, Open Sans, Helvetica, Arial, sans-serif';

export const initializeTypography = (config?: ThemeOptions['typography']): TypographyOptions => {
  return {
    fontFamily,
    ...config,
  };
};
