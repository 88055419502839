import { Overrides } from '@material-ui/core/styles/overrides';

export const initializeMenuOverrides = (config?: Overrides['MuiMenu']): Overrides['MuiMenu'] => {
  return {
    paper: {
      maxHeight: 350,
    },
    ...config,
  };
};
