import { Overrides } from '@material-ui/core/styles/overrides';

export const initializeDialogOverrides = (config?: Overrides['MuiDialog']): Overrides['MuiDialog'] => {
  return {
    paper: {
      margin: 16,
    },
    paperWidthSm: {
      maxWidth: '100%',
    },
    ...config,
  };
};
