import { Overrides } from '@material-ui/core/styles/overrides';

export const initializeInputOverrides = (config?: Overrides['MuiInput']): Overrides['MuiInput'] => {
  return {
    root: {
      fontSize: 14,
    },
    ...config,
  };
};
