import { Overrides } from '@material-ui/core/styles/overrides';

import { fieldBorderRadius } from '@shared/constants/field';

export const initializeOutlinedInputOverrides = (
  config?: Overrides['MuiOutlinedInput']
): Overrides['MuiOutlinedInput'] => {
  return {
    root: {
      fontSize: 14,
      height: 36,
      borderRadius: fieldBorderRadius,
    },
    input: {},
    multiline: {
      height: 'auto',
    },
    inputMarginDense: {
      paddingTop: 4,
      paddingBottom: 4,
    },
    ...config,
  };
};
