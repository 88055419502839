import { Overrides } from '@material-ui/core/styles/overrides';

import { colors } from '@shared/theme/colours';

export const initializeChipOverrides = (config?: Overrides['MuiChip']): Overrides['MuiChip'] => {
  return {
    root: {},
    clickable: {
      backgroundColor: `${colors.white.lightest} !important`,
    },
    ...config,
  };
};
