import { Overrides } from '@material-ui/core/styles/overrides';

export const initializeLinkOverrides = (config?: Overrides['MuiLink']): Overrides['MuiLink'] => {
  return {
    root: {},
    underlineHover: {
      '&:hover': {
        textDecoration: 'none',
      },
    },
    ...config,
  };
};
