import { alpha } from '@material-ui/core/styles';
import { Overrides } from '@material-ui/core/styles/overrides';

import { colors } from '@shared/theme/colours';

export const initializeCheckboxOverrides = (config?: Overrides['MuiCheckbox']): Overrides['MuiCheckbox'] => {
  return {
    root: {},
    colorPrimary: {
      padding: '0 !important',
      marginRight: 8,

      '&:hover': {
        backgroundColor: 'transparent',
      },

      '&.Mui-checked': {
        '&:hover': {
          backgroundColor: 'transparent !important',
        },
      },
    },
    colorSecondary: {
      color: colors.black.darkest,

      '&.Mui-checked': {
        color: colors.black.darkest,
      },

      '&.Mui-disabled': {
        color: alpha(colors.black.darkest, 0.4),
      },
    },
    ...config,
  };
};
