import { Overrides } from '@material-ui/core/styles/overrides';

import { colors } from '@shared/theme/colours';

export const initializeTabsOverrides = (config?: Overrides['MuiTabs']): Overrides['MuiTabs'] => {
  return {
    root: {},
    indicator: {
      backgroundColor: colors.blue.dark,
    },
    ...config,
  };
};
