import { BreakpointsOptions, BreakpointValues } from '@material-ui/core/styles/createBreakpoints';

export const breakpointValues: BreakpointValues = {
  xs: 0,
  sm: 600,
  md: 960,
  lg: 1280,
  xl: 1920,
};

export const initializeBreakpoints = (config?: BreakpointsOptions): BreakpointsOptions => ({
  ...config,
  values: breakpointValues,
});
