import { Overrides } from '@material-ui/core/styles/overrides';

export const initializeDrawerOverrides = (config?: Overrides['MuiDrawer']): Overrides['MuiDrawer'] => {
  return {
    root: {},
    paper: {
      width: 570,

      '@media (max-width: 600px)': {
        width: '100%',
      },
    },
    ...config,
  };
};
