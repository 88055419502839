import { Overrides } from '@material-ui/core/styles/overrides';

import { colors } from '@shared/theme/colours';

export const initializeInputBaseOverrides = (config?: Overrides['MuiInputBase']): Overrides['MuiInputBase'] => {
  return {
    root: {
      '& $disabled': {
        color: colors.black.darkest,
      },
    },
    inputMultiline: {
      resize: 'vertical',
    },
    ...config,
  };
};
