import { Overrides } from '@material-ui/core/styles/overrides';

export const initializeTableCellOverrides = (config?: Overrides['MuiTableCell']): Overrides['MuiTableCell'] => {
  return {
    root: {
      padding: `4px 16px`,
    },
    ...config,
  };
};
