import { Overrides } from '@material-ui/core/styles/overrides';

export const initializeIconButtonOverrides = (config?: Overrides['MuiIconButton']): Overrides['MuiIconButton'] => {
  return {
    root: {
      padding: 0,
    },
    ...config,
  };
};
