import { Overrides } from '@material-ui/core/styles/overrides';

import { colors } from '@shared/theme/colours';

export const initializeTabOverrides = (config?: Overrides['MuiTab']): Overrides['MuiTab'] => {
  return {
    root: {
      minWidth: 'auto',

      '@media (min-width: 600px)': {
        minWidth: 'auto',
      },
    },
    textColorInherit: {
      textTransform: 'none',
      color: colors.grey.main,
      fontWeight: 400,

      '&:hover': {
        color: colors.black.darkest,
      },

      '&$selected': {
        color: colors.black.darkest,
      },
    },
    ...config,
  };
};
