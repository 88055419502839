import { Overrides } from '@material-ui/core/styles/overrides';

import { colors } from '@shared/theme/colours';

export const initializeButtonOverrides = (config?: Overrides['MuiButton']): Overrides['MuiButton'] => {
  return {
    root: {
      borderRadius: 6,
      textTransform: 'none',
      color: 'inherit',

      '&.Mui-disabled .MuiButton-label': {
        color: 'inherit',
      },
      '&.Mui-disabled .MuiButton-label .MuiButton-startIcon': {
        color: 'inherit',
      },
      '&.Mui-disabled .MuiButton-label .MuiButton-startIcon svg': {
        color: 'inherit',
      },
      '&.Mui-disabled .MuiButton-label .MuiButton-endIcon': {
        color: 'inherit',
      },
      '&.Mui-disabled .MuiButton-label .MuiButton-endIcon svg': {
        color: 'inherit',
      },
    },
    disabled: {},
    label: {
      fontSize: 14,
      whiteSpace: 'nowrap',
    },
    contained: {
      '&.Mui-disabled': {
        opacity: 0.5,
      },
    },
    textPrimary: {},
    containedPrimary: {
      color: `${colors.white.lightest} !important`,
      backgroundColor: `${colors.kato.navy[950]} !important`,

      '&:hover': {
        backgroundColor: colors.kato.navy[900],
        boxShadow: 'none',
      },
    },
    containedSizeSmall: {
      padding: '4px 14px',
    },
    outlined: {
      border: `1px solid ${colors.grey.lightish}`,
      backgroundColor: colors.white.lightest,

      '&:hover': {
        borderColor: colors.grey.icon,
        backgroundColor: colors.grey.lighter,
      },
    },
    sizeSmall: {
      height: 36,
    },
    outlinedSizeSmall: {
      height: 36,
    },
    startIcon: {
      marginRight: 5,
    },
    iconSizeMedium: {
      fontSize: 11,

      '&> *:first-child': {
        fontSize: 16,
      },
    },
    ...config,
  };
};
