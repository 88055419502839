import { alpha } from '@material-ui/core/styles';
import { Overrides } from '@material-ui/core/styles/overrides';

import { colors } from '@shared/theme/colours';

export const initializeBackdropOverrides = (config?: Overrides['MuiBackdrop']): Overrides['MuiBackdrop'] => {
  return {
    root: {
      backgroundColor: alpha(colors.black.darker, 0.4),
    },
    ...config,
  };
};
