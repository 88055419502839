import { Overrides } from '@material-ui/core/styles/overrides';

import { colors } from '@shared/theme/colours';

export const initializeTooltipOverrides = (config?: Overrides['MuiTooltip']): Overrides['MuiTooltip'] => {
  return {
    tooltip: {
      color: colors.white.lightest,
      backgroundColor: colors.black.darkest,
    },
    ...config,
  };
};
